import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import Layout from '../../../layouts/mdx';
export const _frontmatter = {
  "product": "signatures",
  "category": "Getting Started",
  "sort": 0,
  "title": "Registering your Application",
  "subtitle": "You will need to register an application with Criipto to get the required credentials (client id and secret) to interact with the Criipto Document Signatures GraphQL API"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Setting up a test application`}</h2>
    <p>{`To start using the GraphQL examples shown here in our documentation you will need to register a test application to get a set of client credentials.`}</p>
    <p>{`A signatures application can be registed by:`}</p>
    <ol>
      <li parentName="ol">{`Going to `}<a parentName="li" {...{
          "href": "https://dashboard.criipto.com/applications/add?tags=signatures"
        }}>{`Criipto Verify`}</a></li>
      <li parentName="ol">{`Selecting the "Test" environment`}</li>
      <li parentName="ol">{`Navigating to "Applications"`}</li>
      <li parentName="ol">{`Click the button "+ Signatures"`}</li>
      <li parentName="ol">{`Filling out the form and clicking submit`}</li>
    </ol>
    <p>{`After creating the application your client credentials should be shown in a popup.`}</p>
    <p>{`Please store the credentials in a safe location. You can refresh or delete client credentials at any time.`}</p>
    <hr></hr>
    <h2>{`Production`}</h2>
    <p>{`You can follow the same steps as in test, after you have purchased a signatures subscription (either with a creditcard or by contacting our sales team).`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      